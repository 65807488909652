<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >设置任务内容</Divider>
    <Form
      ref="newTaskData"
      :model="newTaskData"
      :rules="ruleValidate"
      label-position="top"
    >
      <FormItem
        label="作业日期"
        prop="actionDate"
      >
        <DatePicker
          size="small"
          placeholder="选择作业日期"
          v-model="newTaskData.actionDate"
          type="date"
          style="width:100%"
          :editable="false"
          :clearable="false"
          @on-change="changeActionDate"
        ></DatePicker>
      </FormItem>
      <FormItem
        label="维修项"
        prop="repairId"
      >
        <Select
          v-model="newTaskData.repairId"
          size="small"
          placeholder="选择维修项"
          @on-change="changeRepairId"
        >
          <Option
            v-for="item in repairDictList"
            :key="'repairId'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
        <div class="m-t-5 text-orange">{{checkTips}}</div>
      </FormItem>
      <FormItem
        label="质保情况"
        v-if="repairInfo"
      >
        <div><label class="title">是否在质保期</label>{{repairInfo.inPeriod?"是":"否"}}</div>
        <div v-if="repairInfo.lastDate"><label class="title">上次维修时间</label>{{repairInfo.lastDate}}</div>
        <div><label class="title">质保天数</label>{{repairInfo.period}} 天</div>
        <div><label class="title">材料费用</label>{{repairInfo.materialCost}} 元</div>
        <div><label class="title">人工费用</label>{{repairInfo.workCost}} 元</div>
      </FormItem>
      <FormItem label="计费项">
        <Row :gutter="8">
          <i-col span="12">
            <Checkbox
              size="small"
              v-model="newTaskData.materialCost"
            >材料费用</Checkbox>
          </i-col>
          <i-col span="12">
            <Checkbox
              size="small"
              v-model="newTaskData.workCost"
            >人工费用</Checkbox>
          </i-col>
        </Row>
        <Row :gutter="8">
          <i-col span="12">
            <template v-if="newTaskData.materialCost">
              <InputNumber
                size="small"
                :min="0"
                v-model="newTaskData.materialCount"
              ></InputNumber><span class="p-l-5">次</span>
            </template>
          </i-col>
          <i-col span="12">
            <template v-if="newTaskData.workCost">
              <InputNumber
                size="small"
                :min="0"
                v-model="newTaskData.workCount"
              ></InputNumber><span class="p-l-5">次</span>
            </template>
          </i-col>
        </Row>
      </FormItem>
      <FormItem label="文件选择">
        <div
          class="m-b-5"
          v-if="dataSource===2"
        >
          <Checkbox
            size="small"
            v-model="newTaskData.useReportPic"
          >使用故障上报图片</Checkbox>
        </div>
        <Upload
          multiple
          :before-upload="handleUpload"
          accept="video/*,image/*"
          :data="newTaskData"
          type="drag"
          action
        >
          <div style="padding: 5px 0">
            <Icon
              type="ios-cloud-upload"
              size="40"
              style="color: #3399ff"
            ></Icon>
            <p>选择上传文件</p>
          </div>
        </Upload>
        <p class="text-orange">提示：</p>
        <p>上传文件类型只支持图片和视频。</p>
        <Row v-show="fileList.length||selectedTaskitemFiles.length">
          <i-col span="18">
            <b>文件名称</b>
          </i-col>
          <!-- <i-col span="5">
            <b>文件大小</b>
          </i-col> -->
          <i-col
            span="6"
            class="text-center"
          >
            <b>操作</b>
          </i-col>
        </Row>
        <hr v-show="fileList.length" />
        <Row
          v-for="(file,index) in selectedTaskitemFiles"
          :key="'exitsFile_'+index"
          v-show="selectedTaskitemFiles.length"
        >
          <i-col span="18">{{file.fileName.length>20?file.fileName.substring(0,19)+'...':file.fileName}}</i-col>
          <!-- <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col> -->
          <i-col
            span="6"
            class="text-center"
          >
            <a
              class="delete"
              @click="handleRemoveExitsFiles(file)"
            >删除</a>
          </i-col>
          <i-col span="24">
            <Divider
              dashed
              style="margin:5px 0"
            />
          </i-col>
        </Row>
        <Row
          v-for="(file,fileIndex) in fileList"
          :key="fileIndex"
          v-show="fileList.length"
        >
          <i-col span="18">{{file.name.length>20?file.name.substring(0,19)+'...':file.name}}</i-col>
          <!-- <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col> -->
          <i-col
            span="6"
            class="text-center"
          >
            <a
              class="delete"
              @click="handleRemove(file)"
            >删除</a>
          </i-col>
          <i-col span="24">
            <Divider
              dashed
              style="margin:5px 0"
            />
          </i-col>
        </Row>
      </FormItem>
      <FormItem label="备注">
        <Input
          type="textarea"
          size="small"
          :rows="3"
          v-model.trim="newTaskData.remarks"
          placeholder="备注"
        />
      </FormItem>
      <FormItem>
        <div class="text-right">
          <Button
            size="small"
            type="success"
            :loading="loading"
            :disabled="selectedResourceIdArray.length===0&&selectedTaskitemIdArray.length===0"
            @click="handleSubmit"
          >确认设置</Button>
        </div>

      </FormItem>
    </Form>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getRepairDictList } from '@/api/msp/dictionary'
import { getRepairInfo } from '@/api/msp/taskitem'
import { check } from '@/api/msp/demandV3'
export default {
  data () {
    return {
      taskitemTypeArray: [],
      newTaskData: {
        repairId: null,
        actionDate: '',
        resourceId: '',
        taskId: null,
        extras: '',
        materialCost: false,
        workCost: false,
        remarks: '',
        materialCount: 1,
        workCount: 1,
        reportTroubleId: null,
        useReportPic: false
      },
      loading: false,
      fileList: [],
      ruleValidate: {
        actionDate: [{ required: true, message: ' ', trigger: 'change' }],
        repairId: [{ required: true, type: 'number', message: ' ', trigger: 'change' }]
      },
      repairDictList: [],
      repairInfo: null,
      checkTips: ''
    }
  },
  computed: {
    curDemandType () {
      return this.$store.state.task.curDemandType
    },
    opreateType () {
      return this.$store.state.task.opreateType
    },
    curTaskId () {
      return this.$store.state.task.curTaskId
    },
    dataSource () {
      return this.$store.state.task.dataSource
    },
    selectedFaultIdArray () {
      return this.$store.state.task.selectedFaultIdArray
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.task.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    },
    selectedTaskitemIdArray: {
      get () {
        return this.$store.state.task.selectedTaskitemIdArray
      },
      set (val) {
        this.$store.commit('set_selected_taskitemid_array', val)
      }
    },
    selectedTaskitemFiles: {
      get () {
        return this.$store.state.task.selectedTaskitemFiles
      },
      set (val) {
        this.$store.commit('set_selected_taskitem_files', val)
      }
    },
    curDeviceRapairInfo: {
      get () {
        return this.$store.state.task.curDeviceRapairInfo
      },
      set (val) {
        this.$store.commit('set_cur_device_rapair_info', val)
      }
    }
  },
  created () {
  },
  methods: {
    changeActionDate (date) {
      this.newTaskData.actionDate = date
      if (this.newTaskData.repairId) {
        this.getRepairInfoData()
      }
    },
    changeRepairId () {
      this.checkTips = ''
      if (this.newTaskData.repairId) {
        this.handleCheck().then(res => {
          if (res && this.newTaskData.repairId !== this.curDeviceRapairInfo.repairId) {
            this.checkTips = '当前选中设备所选维修项存在未完成任务'
          }
        })
        if (this.newTaskData.actionDate) {
          this.getRepairInfoData()
        }
      }
    },
    /**
     * 获取维修项数据
     */
    getRepairDictData () {
      getRepairDictList().then(res => {
        if (res && !res.errcode) {
          this.repairDictList = res
        }
      })
    },
    /**
     * 获取资源指定维修项的质保情况
     */
    getRepairInfoData () {
      const params = {
        actionDate: this.newTaskData.actionDate,
        repairId: this.newTaskData.repairId,
        deviceId: this.selectedResourceIdArray[0]
      }
      getRepairInfo(params).then(res => {
        if (res && !res.errcode) {
          this.repairInfo = res
        }
      })
    },
    handleUpload (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    handleRemoveExitsFiles (file) {
      this.selectedTaskitemFiles.splice(this.selectedTaskitemFiles.indexOf(file), 1)
    },
    /**
     * 检验当前选中设备所选维修项是否还存在未完成任务
     */
    handleCheck () {
      return new Promise((resolve, reject) => {
        check({ deviceId: this.selectedResourceIdArray[0], repairId: this.newTaskData.repairId }).then(res => {
          resolve(res)
        })
      })
    },
    handleSubmit () {
      this.$refs.newTaskData.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误并提交？',
            onOk: () => {
              this.loading = true
              // 处理计费次数
              this.newTaskData.materialCount = this.newTaskData.materialCost ? this.newTaskData.materialCount : 0
              this.newTaskData.workCount = this.newTaskData.workCost ? this.newTaskData.workCount : 0

              const uploadData = new FormData()
              uploadData.append('actionDate', this.newTaskData.actionDate)
              uploadData.append('materialCost', this.newTaskData.materialCost)
              uploadData.append('repairId', this.newTaskData.repairId)
              uploadData.append('workCost', this.newTaskData.workCost)
              uploadData.append('extras', this.newTaskData.extras)
              uploadData.append('materialCount', this.newTaskData.materialCount)
              uploadData.append('remarks', this.newTaskData.remarks)
              // uploadData.append('reportTroubleId', this.newTaskData)
              uploadData.append('workCount', this.newTaskData.workCount)
              uploadData.append('useReportPic', this.newTaskData.useReportPic)
              if (this.fileList && this.fileList.length) {
                this.fileList.forEach((file) => {
                  uploadData.append('files', file)
                })
              } else {
                uploadData.append('files', null)
              }
              if (this.dataSource === 2) {
                uploadData.append('reportTroubleId', this.selectedFaultIdArray[0])
              }
              if (this.opreateType === 1) {
                uploadData.append('taskId', this.curTaskId)
                uploadData.append('deviceId', this.selectedResourceIdArray[0])
                try {
                  axios.post(
                    process.env.VUE_APP_API_URL_V2 +
                    '/ooh-msp/v3/demand/setrepairtaskitem',
                    uploadData,
                    {
                      timeout: 15000, // 请求超时
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'ooh-auth': getStorage('ooh-token')
                      },
                      withCredentials: true
                      // onUploadProgress (progress) {
                      //   // 处理上传文件进度条数据
                      //   that.percentage = Math.round(
                      //     (progress.loaded / progress.total) * 100
                      //   )
                      // }
                    }
                  ).then((res) => {
                    this.loading = false
                    if (res.data && res.data.errcode === 0) {
                      this.selectedResourceIdArray = []
                      this.selectedTaskitemIdArray = []
                      if (this.dataSource === 1) {
                        // 重新获取库存资源
                        this.$store.dispatch('getDeviceData')
                      } else {
                        // 重新获取上报资源
                        this.$store.dispatch('getTroubleData')
                      }

                      this.$Notice.success({ desc: '操作成功' })
                      Object.assign(this.$data, this.$options.data())
                    } else {
                      this.$Notice.error({ desc: res.data.errmsg })
                    }
                  })
                } catch (error) {
                  this.loading = false
                }
              } else {
                uploadData.append('taskitemId', this.selectedTaskitemIdArray[0])
                if (this.selectedTaskitemFiles && this.selectedTaskitemFiles.length) {
                  uploadData.append('taskitemFileIds', JSON.stringify(this.selectedTaskitemFiles.map(x => x.id)))
                }
                try {
                  axios.post(
                    process.env.VUE_APP_API_URL_V2 +
                    '/ooh-msp/v3/demand/updaterepairtaskitem',
                    uploadData,
                    {
                      timeout: 15000, // 请求超时
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'ooh-auth': getStorage('ooh-token')
                      },
                      withCredentials: true
                      // onUploadProgress (progress) {
                      //   // 处理上传文件进度条数据
                      //   that.percentage = Math.round(
                      //     (progress.loaded / progress.total) * 100
                      //   )
                      // }
                    }
                  ).then((res) => {
                    this.loading = false
                    if (res.data && res.data.errcode === 0) {
                      this.selectedResourceIdArray = []
                      this.selectedTaskitemIdArray = []
                      this.selectedTaskitemFiles = []
                      this.$store.dispatch('getTaskitemData')
                      this.$Notice.success({ desc: '操作成功' })
                      Object.assign(this.$data, this.$options.data())
                    } else {
                      this.$Notice.error({ desc: res.data.errmsg })
                    }
                  })
                } catch (error) {
                  this.loading = false
                }
              }
            }
          })
        }
      })
    }
  },
  watch: {
    selectedResourceIdArray: {
      deep: true,
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal && newVal.length) {
          this.getRepairDictData()
        }
      }
    }
  }
}
</script>
